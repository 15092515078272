import React, { useRef, useEffect } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import at from "./at.json";
import {
  setUpLegend,
  setUpEventListener,
  getSummitLayerConfig,
  getSummitDataUrl,
} from "./utils";
import { Dropdown } from "./Dropdown";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function Map() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = -84.1407;
  const lat = 34.6615;
  const zoom = 12;
  const summitDataUrl = getSummitDataUrl();

  // Initialize map when component mounts
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/bkgreve/cl7kl1pxb005714of7hx87dka",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("load", function () {
      // load summits on trail
      map.current.loadImage(
        "https://sota-at-images.s3.amazonaws.com/mountain.png",
        function (error, image) {
          if (error) throw error;
          map.current.addImage("on-trail", image);

          // load summits located on AT
          map.current.addSource("points", {
            type: "geojson",
            data: `${summitDataUrl}?summit_type=on_at`,
          });

          // Create mapbox layer for summits on AT
          map.current.addLayer(
            getSummitLayerConfig(
              "summits-on-trail",
              "on-trail",
              "Summits on AT",
              "points"
            )
          );
        }
      );

      // load summits off trail
      map.current.loadImage(
        "https://sota-at-images.s3.amazonaws.com/bushwack_mtn.png",
        function (error, image) {
          if (error) throw error;
          map.current.addImage("off-trail", image);

          // load summits located off AT
          map.current.addSource("bushwack", {
            type: "geojson",
            data: `${summitDataUrl}?summit_type=off_at`,
          });

          // Create mapbox layer for summits off AT
          map.current.addLayer(
            getSummitLayerConfig(
              "summits-off-trail",
              "off-trail",
              "Summits accessible via AT",
              "bushwack"
            )
          );
        }
      );

      // Add route of AT
      map.current.addSource("at-route", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: at.features,
        },
      });

      // Add route of AT as layer
      map.current.addLayer({
        id: "at-route",
        type: "line",
        source: "at-route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#880808",
          "line-width": 4,
        },
      });

      const summitTypes = ["summits-on-trail", "summits-off-trail"];

      summitTypes.forEach((summitType) => {
        setUpEventListener(map.current, summitType);
      });

      map.current.on("load", function () {
        ".mapboxgl-canvas".css("width", "100%");
        ".mapboxgl-canvas".css("height", "100%");
        map.current.resize();
      });

      map.current.addControl(new Dropdown(), "top-right");
      map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

      setUpLegend(map.current, summitTypes, "top-left");
    });
  });

  return (
    <div>
      <div className="holder-map-container">
        <div ref={mapContainer} className="map-container"></div>
      </div>
    </div>
  );
}
