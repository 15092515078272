import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import LegendControl from "mapboxgl-legend";
import "mapboxgl-legend/dist/style.css";

/**
 * Constructs URL for retrieving data from API
 * @returns URL for retrieving summit data
 */
const getSummitDataUrl = () => {
  const baseUrl = window.location.href;
  // note to change the localhost port when working on port that's not 3000
  if (baseUrl === "http://localhost:3000/") {
    return "http://localhost:8000/summits/";
  } else {
    return `${baseUrl}summits/`;
  }
};

/**
 * Structures modals shown on click
 *
 * When a summit is clicked, a modal is shown
 * with details about that summit. This
 * function structures the content shown in
 * the modal
 *
 * @param {String} summit
 * @returns {String} formatted modal data
 */
const createModalData = (summit) => {
  const title = summit.properties.title;
  const code = summit.properties.description;
  const elevation = summit.properties.elevation;
  const points = summit.properties.points;
  return `<b>${title}: ${code}</b><br/><b>Elevation: ${elevation}'</b><br/><b>Points: ${points}</b>`;
};

/**
 * Adds legend to map
 *
 * Each layer is added to a legend that is
 * shown in location
 *
 * @param {mapboxgl.map} map
 * @param {Array} layers
 * @param {String} location
 */
const setUpLegend = (map, layers, location) => {
  const legend = new LegendControl({
    layers: layers,
  });
  map.addControl(legend, location);
};

/**
 * Adds map event listeners for summit type
 *
 * @param {mapboxgl.map} map
 * @param {String} summitType
 */
const setUpEventListener = (map, summitType) => {
  map.on("click", summitType, (e) => {
    const summit = e.features[0];

    const modalData = createModalData(summit);

    new mapboxgl.Popup()
      .setHTML(modalData)
      .setLngLat(summit.geometry.coordinates)
      .addTo(map);
  });

  map.on("mouseenter", summitType, () => {
    map.getCanvas().style.cursor = "pointer";
  });

  map.on("mouseleave", summitType, () => {
    map.getCanvas().style.cursor = "";
  });
};

/**
 * Creates config for adding map layer
 * @param {String} id layer id
 * @param {String} image image name
 * @param {String} name name rendered on legend
 * @param {String} source source of points
 * @returns
 */
const getSummitLayerConfig = (id, image, name, source) => {
  return {
    id: id,
    type: "symbol",
    source: source,
    layout: {
      "icon-image": image,
      "icon-size": 0.15,
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 3],
      "text-anchor": "top",
    },
    metadata: {
      name: name,
    },
  };
};

export {
  setUpLegend,
  setUpEventListener,
  getSummitLayerConfig,
  getSummitDataUrl,
};
