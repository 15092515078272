import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

const listOfUSStates = [
  {
    type: "option",
    value: 1,
    name: "Georgia",
    coordinate: [-83.8382, 34.7174],
  },
  {
    type: "option",
    value: 2,
    name: "North Carolina",
    coordinate: [-82.869698, 35.863098],
  },
  {
    type: "option",
    value: 3,
    name: "Tennessee",
    coordinate: [-82.245201, 36.131302],
  },
  {
    type: "option",
    value: 4,
    name: "Virginia",
    coordinate: [-78.6569, 37.4316],
  },
  {
    type: "option",
    value: 5,
    name: "West Virginia",
    coordinate: [-80.4549, 38.5976],
  },
  {
    type: "option",
    value: 6,
    name: "Maryland",
    coordinate: [-76.6413, 39.0458],
  },
  {
    type: "option",
    value: 7,
    name: "Pennsylvania",
    coordinate: [-77.1945, 41.2033],
  },
  {
    type: "option",
    value: 8,
    name: "New Jersey",
    coordinate: [-74.4057, 40.0583],
  },
  {
    type: "option",
    value: 9,
    name: "New York",
    coordinate: [-74.006, 40.7128],
  },
  {
    type: "option",
    value: 10,
    name: "Connecticut",
    coordinate: [-73.0877, 41.6032],
  },
  {
    type: "option",
    value: 11,
    name: "Massachusetts",
    coordinate: [-71.3824, 42.4072],
  },
  {
    type: "option",
    value: 12,
    name: "Vermont",
    coordinate: [-72.5748, 44.5588],
  },
  {
    type: "option",
    value: 13,
    name: "New Hampshire",
    coordinate: [-71.5724, 43.1939],
  },
  {
    type: "option",
    value: 14,
    name: "Maine",
    coordinate: [-69.4455, 45.2538],
  },
];

export class Dropdown {
  constructor() {}

  onAdd(map) {
    this._map = map;
    let _this = this;

    this.stateDropdown = document.createElement("select");

    listOfUSStates.map((usState) => {
      const newOption = document.createElement("option");
      newOption.type = usState.type;
      newOption.value = usState.value;
      newOption.innerHTML = usState.name;
      newOption.setAttribute("data-center", JSON.stringify(usState.coordinate));
      this.stateDropdown.appendChild(newOption);
    });

    this._container = document.createElement("div");
    this._containerSelect = document.createElement("div");
    this._containerSelect.className = "mapbox-ctrl-group mapboxgl-ctrl";
    this._containerSelect.addEventListener("change", (event) => {
      const selectedOption = event.target.value;
      if (selectedOption) {
        let coordinate = JSON.parse(
          event.target.options[event.target.selectedIndex].getAttribute(
            "data-center"
          )
        );
        coordinate = new mapboxgl.LngLat(coordinate[0], coordinate[1]);
        this._map.setCenter(coordinate);
      }
    });
    this._containerSelect.appendChild(this.stateDropdown);
    this._container.appendChild(this._containerSelect);

    return this._container;
  }
}
