import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">
        Created by <a href="http://rebeccakeyphd.com/">KO4KVG</a> and{" "}
        <a href="http://bkgreve.com/">KO4KVH</a>
      </p>
      <a
        href="https://www.flaticon.com/free-icons/mountain"
        title="mountain icons"
      >
        Mountain icons created by Freepik - Flaticon
      </a>
    </div>
  );
}
