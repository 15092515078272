import React from "react";
import "./Header.css";

export default function Header() {
  return (
    <div className="header">
      <h1>SOTA-AT: SOTA Summits Along the Appalachian Trail</h1>
    </div>
  );
}
